<template>
  <div class="groups">
    <main>
      <template v-if="!dataLoaded">
        <div class="text-center text-gray">
          <i class="fa fa-spinner fa-spin icon"></i>
          <p>加載中</p>
        </div>
      </template>
      <template v-else>
        <div class="text-center text-gray" v-if="!groupList.length">
          暫無團購信息
        </div>
        <template v-else>
          <groups-item v-for="(group, index) in groupList" :key="index"
                       :info="group" @claim="claimCoupon(index)" />
        </template>
      </template>
    </main>
  </div>
</template>

<script>
import GroupsItem from '../components/layout/GroupsItem'
import api from '../api/'
import { mapGetters, mapActions } from 'vuex'
import Cookies from 'js-cookie'
export default {
  name: 'official-group',
  components: {
    GroupsItem
  },
  data: () => ({
    page: 1,
    total: 1,
    groupList: [],
    dataLoaded: false,
    listFetching: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  mounted () {
    const { ref, sourceId } = this.$route.query
    const refer = ref || sourceId
    // 当前用户未登录，添加绑定逻辑
    if (refer && !this.user.uid) {
      Cookies.set('sourceId', refer)
    }
    this.fetchList()
  },
  methods: {
    ...mapActions(['setLoginShow']),
    fetchList () {
      this.listFetching = true
      return api.fetchOfficialCoupon({ uid: this.user.uid, page: this.page }).then(res => {
        this.listFetching = false
        if (res.errors) {
          return []
        }
        const { count, data } = res
        if (!this.dataLoaded) {
          this.dataLoaded = true
          this.total = count
        }
        data.map(item => {
          if (item.coupon.claim === item.coupon.total) {
            item.isFull = true
          }
          item.coupon.startAt = this.$moment(item.coupon.beginAt).format('MM月DD日 HH:mm')
          return item
        })
        this.groupList = this.groupList.concat(data)
      }).catch(e => {
        console.log(e)
        this.listFetching = false
      })
    },
    claimCoupon (index) {
      const user = this.user
      if (!user.uid) {
        this.$confirm({
          title: '請登錄',
          cancel: false,
          okText: '去登錄',
          onCancel: (modal) => {
            modal.close()
            this.setLoginShow(true)
          }
        })
        return
      }
      const group = this.groupList[index]
      const coupon = group.coupon
      const { couponNo, beginAt } = coupon
      if (beginAt && new Date(beginAt).getTime() > Date.now()) {
        group.loading = true
        this.$set(this.groupList, index, group)
        api.triggerCoupon(couponNo).then(data => {
          group.loading = false
          this.$set(this.groupList, index, group)
          if (data.errors) {
            this.$toast({
              message: data.errors.message
            })
            return
          }
          group.fetched = true
          group.users.count += 1
          group.users.avatars.push(this.user.avatar)
          this.$set(this.groupList, index, group)
          this.$confirm({
            title: `您已成功參團，本團於 ${group.coupon.startAt} 成團，成團後優惠券自動發到您賬戶哦`,
            cancel: false
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .groups {
    main {
      max-width: 480px;
      margin: 0 auto;
      padding: 1rem;
      i.icon {
        font-size: 2.5rem;
        margin-bottom: 10px;
      }
    }
  }
</style>
